@import 'styles/variables';

.block {
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: $smallTitleFontSize;
  line-height: 52px;
  text-align: center;
  color: $colorTextBlackMain;
  margin-bottom: 16px;
}

.subheadingText {
  font-size: $mediumFontSize;
  line-height: 24px;
  text-align: center;
  color: $colorTextBlackMain;
  opacity: 0.6;
  width: 455px;
  margin: 0 auto 34px;
}

.coins {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
}

.coinsButton {
  margin: 0 auto;
  max-width: 150px;
}

.coinsThreeInRow {
  max-width: 960px;
}

.comingSoon {
  margin: 0 auto 55px;
  max-width: 900px;
}

.supportedText {
  margin-bottom: 38px;
}

.supportedCoins {
  margin-bottom: 30px;

  .coinButton {
    cursor: pointer;

    &:hover {
      background: $colorLightGrayHover;
    }
  }
}

.coinIcon {
  margin-right: 5px;
}

.coinButton {
  width: 216px;
  height: 40px;
  background: $colorLightGray;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  margin: 6px;
  cursor: default;

  .p,
  p {
    font-weight: 500;
    font-size: $smallFontSize;
    line-height: 19px;
    color: #000;
    opacity: 0.8;
    margin-right: 8px;
  }

  span {
    margin-bottom: 1px;
    font-size: $xsFontSize;
    line-height: 19px;
    text-transform: uppercase;
    color: #aaa;
    opacity: 0.6;
  }
}

@media (--mobile) {
  .subheadingText {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .supportedCoins {
    max-width: 650px;
  }
}

@media (--mobile), (--tablet) {
  .coins {
    width: 100%;
  }

  .comingSoon,
  .ethFamily {
    display: none;
  }
}
