@import 'styles/variables';

.block {
  padding-bottom: 120px;
  background-color: white;
}

.blockWrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 74px;
}

.imageBox {
  width: 559px;
  position: relative;
}

.image {
  height: 100%;
}

.imageMessageMail {
  position: absolute;
  left: -20px;
  z-index: 3;
  top: 160px;
  width: 390px;
}

.imageMessageIos {
  position: absolute;
  right: -20px;
  bottom: 220px;
  width: 390px;
}

.content {
  width: 413px;
  padding-top: 50px;
}

.title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: $smallTitleFontSize;
  line-height: 52px;
  color: $colorTextBlackMain;
  margin-bottom: 30px;
}

.contentBox {
  margin-bottom: 50px;

  img {
    margin-bottom: 16px;
  }
}

.contentText {
  font-size: $mediumFontSize;
  line-height: 24px;
  color: $colorTextBlackMain;
  opacity: 0.6;
  margin-bottom: 10px;
}

@media (--mobile) {
  .imageBox,
  .imageBox img {
    width: 100%;
  }
}

@media (--mobile), (--xs-tablet) {
  .blockWrapper {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0;
  }

  .content {
    width: 100%;
  }
}
