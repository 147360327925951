@import 'styles/variables';
@import 'styles/mixins';

.container {
  background-color: white;
}

.mainBlock {
  padding: 60px 0 0;
  box-shadow: 0 0 16px rgb(0 0 0 / 10%);
  border-radius: 40px;
  background-color: $colorBackgroundMainBlue;
  background-image: url(icons/bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1440px;
  min-height: 653px;
  margin: 0 auto;

  @media (min-width: 1440px) {
    max-width: 1400px;
  }

  @media (min-width: 1024px) and (max-width: 1440px) {
    max-width: 95%;
  }

  @media (min-width: 768px) and (max-width: 1020px) {
    min-height: 920px;
  }

  @media (min-width: 1021px) and (max-width: 1205px) {
    min-height: 1000px;
  }

  @media (max-width: 345px) {
    min-height: 1200px;
    max-height: 1200px;
  }

  &Wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-content: space-between;
  }
}

.greeting,
.additionalInfo {
  width: 50%;
}

.mainTitle {
  position: absolute;
  top: -5%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  padding: 2px 20px;
  font-family: 'Poppins', serif;
  text-transform: uppercase;
  color: $colorTextWhite;
  opacity: 0.8;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.titlePage {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: $colorTextWhite;
  padding-top: 15px;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.subtitleText {
  font-size: $largeFontSize;
  line-height: 26px;
  color: $colorTextWhite;
  opacity: 0.8;
  width: 480px;
  height: 113px;
  padding-top: 15px;
}

.advantages {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;

  &Main {
    font-family: Poppins, serif;
    font-style: normal;
    font-weight: 600;
    font-size: $smallTitleFontSize;
    line-height: 52px;
    color: $colorTextWhite;
    min-width: 115px;
  }

  &Item {
    display: flex;
    margin-bottom: 30px;
    align-items: center;

    &:nth-child(2n) {
      margin-left: 15px;
    }
  }

  &Text {
    margin-left: 20px;
    font-size: $xsFontSize;
    line-height: 18px;
    color: $colorTextWhite;
    opacity: 0.8;

    span {
      display: block;
    }
  }
}

.partners {
  display: flex;
  justify-content: space-between;
}

.partnersBox {
  &Inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
    margin-bottom: 40px;

    &:last-child {
      margin-left: 20px;
    }
  }

  &Items {
    display: flex;
    align-items: center;
  }

  &Title {
    white-space: nowrap;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: $smallFontSize;
    line-height: 36px;
    color: $colorTextWhite;
  }
}

.partner {
  &:nth-child(2) {
    margin-left: 20px;
  }
}

.wallet {
  margin-left: -2px;
}

.trustpilot {
  margin-top: -10px;
  margin-left: -5px;
}

.firstWord {
  color: $colorGold;
}

.calculator {
  width: 482px;
  position: absolute;
  right: 0;
  top: 20px;
}

.partnersBoxInner:last-of-type {
  margin-right: 12px;
}

@media (--mobile) {
  .mainBlock {
    padding-top: 0;
    min-height: 940px;
    border-radius: 0 0 40px 40px;
    background-image: url(icons/calculator-background.svg);
    background-position: bottom;
    background-size: 100%;
  }

  .mainTitle {
    position: static;
    top: 0;
    text-align: center;
    max-width: fit-content;
    margin-top: 65px;
  }

  .titlePage {
    font-size: 32px;
    line-height: 40px;
    padding-top: 15px;
    margin-bottom: 5px;
    text-align: center;
  }

  .calculator,
  .additionalInfo {
    width: 100%;
  }

  .advantages {
    justify-content: center;
    align-items: flex-start;

    &Text {
      width: fit-content;
      text-align: center;
    }

    &Main {
      font-size: 36px;
    }

    &Item {
      &:nth-child(3),
      &:nth-child(4) {
        margin-bottom: 0;
      }
    }
  }

  .partners {
    margin-top: 55px;
    padding: 0 25px;

    &BoxInner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 0;
      margin-bottom: 15px;

      &:last-child {
        margin-left: 0;
      }
    }

    &BoxItems {
      gap: 14px;
    }
  }

  .partner {
    &:nth-child(1) {
      margin-left: 10px;
    }

    &:nth-child(2) {
      margin-left: 5px;
    }
  }

  .guarda {
    margin-left: 5px;
  }

  .subtitleText {
    margin: 0 auto;
    margin-bottom: 30px;
    font-size: 15px;
    line-height: 24px;
    width: fit-content;
    text-align: center;
  }
}

@media (--xs-tablet) {
  .mainBlock {
    padding-top: 0;
    border-radius: 0 0 40px 40px;
  }

  .calculator,
  .additionalInfo {
    width: 730px;
  }

  .titlePage {
    font-size: 59px;
  }
}

@media (--tablet) {
  .mainBlock {
    border-radius: 40px;
  }

  .calculator,
  .additionalInfo {
    width: 872px;
  }
}

@media (--tablet), (--xs-tablet) {
  .mainBlock {
    background-image: none;
  }

  .titlePage {
    text-align: inherit;
  }

  .partnersBoxInner {
    margin-top: 30px;
  }

  .subtitleText {
    width: 100%;
    height: 100%;
    max-width: none;
    margin: 55px 0 30px 10px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }
}

@media (--mobile), (--tablet), (--xs-tablet) {
  .mainBlockWrapper {
    align-items: center;
    flex-wrap: nowrap;
  }

  .advantages {
    grid-template-columns: 1fr 100px;
    grid-template-rows: 1fr 80px;
    margin: 0 auto;
    max-width: 270px;

    &Main {
      padding-bottom: 4px;
    }

    &Text {
      min-width: 100px;
      max-width: 140px;
      text-align: inherit;
      margin: 0;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
    }

    &Item {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;

      &:last-of-type {
        padding-bottom: 60px;
      }
    }
  }

  .greeting {
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 2;
    width: auto;
  }

  .titlePage {
    overflow: hidden;
  }

  .additionalInfo {
    order: 3;
  }

  .calculator {
    order: 1;
    position: relative;
    margin-top: 10px;
  }

  .subtitleText {
    height: 100%;
    max-width: none;
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
  }
}

@media (--xs-tablet), (--tablet) {
  .partnersInner {
    width: 50%;
  }

  .mainBlock {
    min-height: 700px;
  }
}

@media (--mobile), (--xs-tablet), (--tablet) {
  .desktopOnly {
    display: none;
  }

  .trustpilot {
    margin-top: 0;
  }
}

@media (--desktop) {
  .mobileOnly {
    display: none;
  }
}
